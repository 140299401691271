@charset "UTF-8";
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */
/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
/* stylelint-disable */
/* stylelint-enable */
/* Font */
/* Base palette */
/* Brand palette */
/* Theme */
/* State palette */
.section--serial-tag {
  padding: 6rem 0 clamp(2rem, -3.00752vw + 2.706766875rem, 0rem);
}
.section--serial-tag-grey {
  background-color: #DCDCDC;
}
.section--serial-tag-grey .qpac.serial-tag {
  border-color: #1C1D21;
  color: #1C1D21;
}
.section--serial-tag-grey .qpac.serial-tag .serial-tag__title {
  border-left-color: #1C1D21;
}

.qpac.serial-tag {
  display: inline-flex;
  border: 0.0625rem solid #DCDCDC;
}
.qpac.serial-tag .serial-tag__no {
  padding: 0.4375rem 0.5625rem 0.4375rem 0.4375rem;
  font-size: 0.75rem;
  line-height: 120%;
  text-transform: uppercase;
}
@media screen and (min-width:991px) {
  .qpac.serial-tag .serial-tag__no {
    padding-bottom: 0.375rem;
  }
}
.qpac.serial-tag .serial-tag__title {
  display: flex;
  flex-direction: row;
  padding: 0.4375rem 1.4375rem 0.4375rem 0.4375rem;
  border-left: 0.0625rem solid #DCDCDC;
  gap: 0.5rem;
}
@media screen and (min-width:991px) {
  .qpac.serial-tag .serial-tag__title {
    padding-bottom: 0.375rem;
  }
}
.qpac.serial-tag .serial-tag__title h2 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.serial-tag .serial-tag__title span {
  font-size: 0.75rem;
  line-height: 120%;
}
.qpac.serial-tag.black-variant {
  border: 0.0625rem solid #1C1D21;
  color: #1C1D21;
}
.qpac.serial-tag.black-variant .serial-tag__title {
  border-left: 0.0625rem solid #1C1D21;
}

.section--customer-stories-banner {
  background-color: #00FFAE;
}

.qpac.customer-stories-banner {
  padding-top: 6rem;
}
.qpac.customer-stories-banner .serial-tag {
  margin-bottom: 2rem;
}
.qpac.customer-stories-banner article {
  padding: 2.4375rem 0 clamp(3.5rem, 3.7594vw + 2.61654125rem, 6rem) clamp(1rem, 61.91537vw - 37.348830625rem, 18.375rem);
}
@media screen and (min-width:991px) {
  .qpac.customer-stories-banner article {
    padding-top: 0;
  }
}
.qpac.customer-stories-banner article h2 {
  display: inline;
  margin-bottom: 0;
  color: #1C1D21;
  font-size: clamp(1.5rem, 3.7594vw + 0.61654125rem, 4rem);
  font-weight: 500;
  letter-spacing: -0.03rem;
  line-height: 105%;
  text-transform: uppercase;
}
@media screen and (min-width:991px) {
  .qpac.customer-stories-banner article h2 {
    letter-spacing: -0.08rem;
  }
}
.qpac.customer-stories-banner article h2.quoted::before {
  margin-left: -0.875rem;
  content: "“";
  font-size: clamp(1.5rem, 3.7594vw + 0.61654125rem, 4rem);
  font-weight: 500;
}
@media screen and (min-width:991px) {
  .qpac.customer-stories-banner article h2.quoted::before {
    margin-left: -2.375rem;
  }
}
.qpac.customer-stories-banner article h2.quoted::after {
  margin-left: -0.875rem;
  content: "	”";
  font-size: clamp(1.5rem, 3.7594vw + 0.61654125rem, 4rem);
  font-weight: 500;
}
@media screen and (min-width:991px) {
  .qpac.customer-stories-banner article h2.quoted::after {
    margin-left: -2.375rem;
  }
}
.qpac.customer-stories-banner article .customer-stories-banner__author {
  margin-top: 2.5rem;
}
@media screen and (min-width:991px) {
  .qpac.customer-stories-banner article .customer-stories-banner__author {
    margin-top: 1.4375rem;
  }
}
.qpac.customer-stories-banner article .customer-stories-banner__author p {
  color: #1C1D21;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.customer-stories-banner article .customer-stories-banner__author address {
  margin-top: 0.125rem;
  color: #1E2128;
  font-size: 0.75rem;
  font-style: normal;
  line-height: 120%;
  opacity: 0.8;
  text-transform: uppercase;
}
.qpac.customer-stories-banner article .l-btn {
  margin-top: clamp(1.5rem, 2.25564vw + 0.969925rem, 3rem);
}